import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EventsService {
    private subject = new Subject<any>();

    constructor() { }

    publish(name: string, data: any) {
        this.subject.next({ name: name, data: data });
    }


    getObservable(): Subject<any> {
        return this.subject;
    }
}
