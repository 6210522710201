<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button color="medium" (click)="cancel()">Cancel</ion-button>
    </ion-buttons>
    <ion-title>Modify Load</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="confirm()" [strong]="true">Confirm</ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content class="ion-padding">
  <ion-item>
    <ion-label position="stacked">Please give a reson for modification</ion-label>
    <ion-input labelPlacement="stacked"  [(ngModel)]="reason" placeholder="Reason"></ion-input>
  </ion-item>
  <ion-item>
    <ion-label position="stacked">Proposed Collection Date</ion-label>
    <ion-input
      value="{{ newDate}}"
      id="fromDate"
      class="ion-text-end">
    </ion-input>
    <ion-popover trigger="fromDate" size="cover">
      <ng-template>
        <ion-datetime
          presentation="date"
          [(ngModel)]="newDate"
          locale="en-GB">
        </ion-datetime>
      </ng-template>
    </ion-popover>
  </ion-item>
</ion-content>