<ion-app>
  <ion-split-pane contentId="main-content" [when]="false">
    <ion-menu contentId="main-content" side="end">
      <ion-content>
        <ion-list id="inbox-list">
          <!-- <ion-list-header>Partner Portal</ion-list-header>
          <ion-note>App version: 0.0.2 {{enviroName}}</ion-note> -->
          <!-- <global-header></glo bal-header> -->
          <ion-title>{{'menu'|translate}}</ion-title>
          <ion-note style="margin-top:20px;padding-left: 23px;">{{'user_greeting' | translate}} <span
              [innerHTML]="getFullName()"></span></ion-note>
          <ion-menu-toggle auto-hide="false" *ngFor="let p of appPages; let i = index">
            <ion-item *ngIf="!p.hidden" (click)="selectedIndex = i" routerDirection="root" [routerLink]="[p.url]"
              lines="none" detail="false" [class.selected]="selectedIndex == i">
              <ion-icon slot="start" [ios]="p.icon + '-outline'" [md]="p.icon + '-sharp'"></ion-icon>
              <ion-label>{{ p.title }}</ion-label>
            </ion-item>
          </ion-menu-toggle>
          <ion-menu-toggle auto-hide="false">
            <ion-item routerDirection="root" routerLink="/login" lines="none" detail="false" (click)="logout()">
              <ion-icon slot="start" ios="log-out-outline" md="log-out-sharp"></ion-icon>
              <ion-label>{{'logout' | translate}}</ion-label>
            </ion-item>
          </ion-menu-toggle>
        </ion-list>

        <!-- <ion-list id="labels-list">
          <ion-list-header>Labels</ion-list-header>

          <ion-item *ngFor="let label of labels" lines="none">
            <ion-icon slot="start" ios="bookmark-outline" md="bookmark-sharp"></ion-icon>
            <ion-label>{{ label }}</ion-label>
          </ion-item>
        </ion-list> -->
      </ion-content>
    </ion-menu>
    <ion-router-outlet id="main-content"></ion-router-outlet>
  </ion-split-pane>
</ion-app>