import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Platform } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../environments/environment';
import { SessionSettingsService } from './services/session-settings';
import { CommonUiProvider } from './services/common-ui';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
  //standalone: true // Add this line
})
export class AppComponent {
  public selectedIndex = 0;
  public enviroName: string = environment.enviroName;
  public userFullName: string = "";
  public appPages = [
    {
      title: 'Collections Requests',
      url: '/home',
      icon: 'browsers',
      hidden: false
    },
    // {
    //   title: 'Invoicing',
    //   url: '/collection-orders',
    //   icon: 'albums',
    //   hidden: false
    // },
    // {
    //   title: 'Load Complaints',
    //   url: '/load-compaints',
    //   icon: 'albums',
    //   hidden: false
    // },
    {
      title: 'Login',
      url: '/login',
      icon: 'log-in',
      hidden: true
    },
    {
      title: 'Reset Password',
      url: '/resetpassword',
      icon: 'log-in',
      hidden: true
    }
  ];
  public labels = ['Family', 'Friends', 'Notes', 'Work', 'Travel', 'Reminders'];
  constructor(private platform: Platform,
    private router: Router, private translate: TranslateService,
    public sessionSettings: SessionSettingsService,
    public commonUI: CommonUiProvider) {
      this.initializeApp();
     }

  initializeApp() {
    this.platform.ready().then(() => {
      this.sessionSettings.load().then(() => {
        if (!this.sessionSettings.currentToken) {
          //add exception for reset password
          // let routerState: RouterState = this.router.routerState;
          // let routerStateSnapshot: RouterStateSnapshot = routerState.snapshot;
          let currentUrl: string =  window.location.href; //routerStateSnapshot.url;
          if(!currentUrl.includes("resetpassword")){
            this.router.navigateByUrl('login');
          }
        } 
      }).catch(() => {
        this.router.navigateByUrl('login');
      });
      
      //this.statusBar.styleDefault();
      //this.splashScreen.hide();
    });
  }

  //for embedding pages in other apps
  // loginWithToken(token:string, redirectUrl: string) {
  //   this.commonUI.loader.show();
  //   this.securityService.loginWithToken(token).then(
  //     (res) => {
  //       this.sessionSettings.loggedInOffline = false;
  //       this.sessionSettings.currentToken = res.token;
  //       this.sessionSettings.currentUser = res.user;
  //       this.sessionSettings.siteList = res.sites;
  //       this.sessionSettings.departmentList = res.departments;=
  //       this.sessionSettings.permissionList = res.permissionList;
  //       this.sessionSettings.persist().then(() => {
  //         this.commonUI.loader.hide();
  //         if (redirectUrl) {
  //           this.nav.setRoot(redirectUrl);
  //         } else {
  //           this.nav.popToRoot().then(() => {
  //             this.nav.setRoot("HomePage");

  //           });
  //         }

  //       });

  //     },
  //     (err) => {
  //       this.commonUI.loader.hide();
  //       this.nav.setRoot("LoginPage");
  //     }
  //   );
  // }

  getFullName():string{
    if (this.sessionSettings.currentUser) {
      let user = this.sessionSettings.currentUser;
      return user.firstName + ' ' + user.lastName;
    }
    return "";
  }

  logout() {
    this.sessionSettings.load().then(() => {
      this.sessionSettings.currentToken = null;
      this.sessionSettings.persist();
      //this.events.publish('loggedout', {});
    }).catch(() => {
      this.sessionSettings.currentToken = null;
      this.sessionSettings.persist();
      //this.events.publish('loggedout', {});
    });
  }

  showMenu(){
    
  }
}
