import { Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular';

import { SessionSettingsService } from './session-settings';
import { TranslateService } from '@ngx-translate/core';
import { EventsService } from './events.service';

/*
  Generated class for the GlobalLogoutFactoryProvider provider.

  See https://angular.io/guide/dependency-injection for more info on providers
  and Angular DI.
*/
@Injectable()
export class GlobalLogoutFactoryProvider {

  constructor(public sessionSettings: SessionSettingsService, 
    public alertCtrl: AlertController,
    public translate: TranslateService, public events: EventsService) {
    
  }


  logout(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.sessionSettings.load().then(() => {
        this.sessionSettings.currentToken = null;
        this.sessionSettings.persist();
        this.events.publish('loggedout', {});
        resolve(null);
      }).catch(() => {
        this.sessionSettings.currentToken = null;
        this.sessionSettings.persist();
        this.events.publish('loggedout', {});
        reject();
      });

    })
  };
}
