import { enableProdMode, importProvidersFrom } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppComponent } from './app/app.component';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { IonicStorageModule } from '@ionic/storage-angular';
import { Drivers } from '@ionic/storage';
import { bootstrapApplication } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.log(err));

// bootstrapApplication(AppComponent, {
//     providers: [
//       {provide: RouteReuseStrategy, useClass: IonicRouteStrategy},
//       importProvidersFrom(IonicModule.forRoot()),
//       //when application runs it will create local storage and index db
//       importProvidersFrom(IonicStorageModule.forRoot({
//         name: '_portalDb',
//         driverOrder: [Drivers.LocalStorage, Drivers.IndexedDB]
//       }))
//     ]
// });
