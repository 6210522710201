import { Injectable } from '@angular/core';


import { environment } from '../../environments/environment';
/*
  Generated class for the UrlFactoryProvider provider.

  See https://angular.io/guide/dependency-injection for more info on providers
  and Angular DI.
*/
@Injectable()
export class UrlFactoryProvider {

    getApiUrl() {
        return environment.restUrl;

    }

    isTestOrDev():string {
      var currentUrl = this.getApiUrl();
      if (currentUrl.indexOf("test") >= 0) {
        return " (test api)";
      }
      if (currentUrl.indexOf("tracy.dontwaste.co.za") >= 0 || currentUrl.indexOf("tracyrb.dontwaste.co.za") >= 0) {
        return "";
      }
      return " (dev api)"; 
    }

    constructor() {
    
  }

}
