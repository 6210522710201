import { HttpClient } from '@angular/common/http';
import { Component, ViewEncapsulation } from '@angular/core';
import { environment } from '../../environments/environment';


/**
 * Generated class for the GlobalHeaderComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
  selector: 'global-header',
  template: `
  <ion-toolbar>
    <ion-list-header>Partner Portal</ion-list-header>
    <ion-note>App version: {{ version }} {{enviroName}}</ion-note>
    <ion-buttons slot="end">
    <ion-menu-button autoHide="false"></ion-menu-button>
      </ion-buttons>
  </ion-toolbar>

 `,
 styles: ['ion-list-header { font-size: 22px;font-weight: 600;min-height: 20px;padding-left: 10px; } ion-note {padding-left: 10px;margin-bottom: 30px;}'],
 encapsulation: ViewEncapsulation.None
})
export class GlobalHeaderComponent {

  text: string;
  version: string;
  isEmbedded: boolean = false;
  enviroName: string = environment.enviroName;

  constructor(public http: HttpClient) {
    this.http.get('assets/version.json').subscribe(data => {
      this.version = data["version"];
    });
  }
 //  <ion-list-header>Partner Portal</ion-list-header>
  //
  //<ion-menu-button></ion-menu-button>
}
