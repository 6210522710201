import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-reject-modal-component',
  templateUrl: './reject-modal-component.component.html',
  styleUrls: ['./reject-modal-component.component.scss'],
})
export class RejectModalComponentComponent  implements OnInit {
  reason:string = "";
  newDate: Date = new Date();
  constructor(private modalCtrl: ModalController) { }

  ngOnInit() {}

  cancel() {
    return this.modalCtrl.dismiss(null, 'cancel');
  }

  confirm() {
    return this.modalCtrl.dismiss(this.reason, 'confirm');
  }

}
