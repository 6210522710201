import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHeaders } from '@angular/common/http';
import { HttpHandler } from '@angular/common/http';

import { SessionSettingsService } from "./session-settings";
import { PersistedUserDataProvider } from "./persisted-user-data";

@Injectable()
export class HttpInterceptorProvider implements HttpInterceptor {
    constructor(private sessionSettings: SessionSettingsService, private persistedUserData: PersistedUserDataProvider) {

    }

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    let locale: string = "";
    let siteid: string = "";
    if (this.persistedUserData) {
      if (this.persistedUserData.settings.preferredLanguage) {
        locale = this.persistedUserData.settings.preferredLanguage;
      }
    }
    if (this.sessionSettings) {
      if (this.sessionSettings.siteList && this.sessionSettings.siteList.length>0)
        siteid = this.sessionSettings.siteList[0].siteId.toString();
    }
    const headers = new HttpHeaders({
      'Authorization': 'Bearer: ' + this.sessionSettings.currentToken,
          'sim-serialnumber': '',
          'app-version': this.sessionSettings.appVersion,
          'dwapi-locale': locale,
          'dwapi-app': "partner",
          'dwapi-sites': siteid
            
        });
        const newReq = req.clone({
            headers
        });

        return next.handle(newReq);
    }
}
