import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/login',
    pathMatch: 'full',
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then(m => m.LoginPageModule)
  },
  {
    path: 'home',
    loadChildren: () => import('./pages/home/home.module').then(m => m.HomePageModule)
  },
  {
    path: 'collection-orders',
    loadChildren: () => import('./pages/collection-orders/collection-orders.module').then( m => m.CollectionOrdersPageModule)
  },
  {
    path: 'load-compaints',
    loadChildren: () => import('./pages/load-compaints/load-compaints.module').then( m => m.LoadCompaintsPageModule)
  },
  {
    path: 'no-access',
    loadChildren: () => import('./pages/no-access/no-access.module').then( m => m.NoAccessPageModule)
  },
  {
    path: 'resetpassword',
    children :[
      {
        path: ':token',
        loadChildren: () => import('./pages/reset-password/reset-password.module').then( m => m.ResetPasswordPageModule)
      }
    ]
    
  },
  {
    path: 'upload-documents',
    loadChildren: () => import('./pages/documents/upload-documents/upload-documents.module').then( m => m.UploadDocumentsPageModule)
  },
  {
    path: 'view-documents',
    loadChildren: () => import('./pages/documents/view-documents/view-documents.module').then( m => m.ViewDocumentsPageModule)
  }





];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
