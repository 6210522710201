import { HttpClient } from '@angular/common/http';
import { Injectable  } from '@angular/core';
import { NavParams} from '@ionic/angular';
import { Storage } from '@ionic/storage';

/*
  Generated class for the NavParamProvider provider.

  See https://angular.io/guide/dependency-injection for more info on providers
  and Angular DI.
*/
@Injectable()
export class NavParamProvider {

  constructor(public http: HttpClient, public navParams: NavParams, private storage: Storage) {
    
  }

  get(field: string): Promise<any> {
    
    return new Promise<NavParams>((resolve, reject) => {
      if (this.navParams.get(field) === undefined) {
        //we don't have it in our navparams, so check is we have it in our storage
        this.storage.get('navParams').then((val) => {
          let retVal = new CustomNavParam();
          if (val!==null) {
            retVal.data = val.data;
            resolve(retVal.get(field));
          } else {
            resolve(null);
          }
          
        }).catch((err) => {
          reject();
        });
      } else {
        this.storage.set("navParams", this.navParams).then(() => {
          resolve(this.navParams.get(field));
        }).catch(() => {
          reject();
        });
      }
    })
  }

  clear(): Promise<any> {
    
    return new Promise<NavParams>((resolve, reject) => {
      this.storage.remove('navParams').then(() => {
        
        resolve(null);
      }).catch((err) => {
        reject();
      });
    })
  }

}


export class CustomNavParam {
  data: any;
  get(fieldName: string) {
    return this.data[fieldName];
  }
}
