import { Injectable } from '@angular/core';
import { Platform } from "@ionic/angular";

import { SqlProvider } from './sql';
import { PersistedSettings } from '../shared/models/models';



/*
  Generated class for the PersistedUserDataProvider provider.

  See https://angular.io/guide/dependency-injection for more info on providers
  and Angular DI.
*/
@Injectable()
export class PersistedUserDataProvider {
    private registeredListIndex: number = 0;
    
    //0 - unregistered
    //1 - email activation required
    //2 - OTP required
    //3 - registered

    

    settings: PersistedSettings = {
        preferredLanguage: 'en',
        country: 'ZA',
        lastLoginEmail: null,
        profilePic: "img/defaultavatar.png",
        mobileNumber: '',
        defaultSite:null,
        departments: null,
        tenants: null,
        tenantWaste: null,
        tenantContainers: null,
        loadComplaints: null,
        sites: null,
        authToken: '',
        offlinePhotos: [],
        offlineSiteTenantCaptured: [],
        reverseBillUrl: null,
        currentUser: null,
        shownHelp: [],
        loadTypes: null,
        offLineLastLogin: null,
        defaultSiteAppMeta: null,
      regProcessStep: 0,
      dynamicFunctions: null,
      defaultAppPage: "TilePage"
    };

    constructor(public sql: SqlProvider, public platform: Platform) {

        
    }

    private convertFromJsonObject(settings: PersistedSettings) {
        this.settings = settings;
    }

    load(index: number): Promise<any> {
        return new Promise<any>((resolve, reject) => {
             if (index > 0) {
                  this.registeredListIndex = index;
             }
          let sql = "SELECT * FROM persistedUserData WHERE id=" + this.registeredListIndex
              this.sql.query(sql).then(result => {
                  let settings: any;
                  if (!(document.URL.indexOf('http://localhost') >= 0 || document.URL.indexOf('https://localhost') >= 0)) {
                      settings = result.rows.item(0);
                  } else {
                    if (result.rows.length > 0) {
                      settings = result.rows[0];
                    }
                      
                  }
                  if (settings) {
                    let tempObj = JSON.parse(settings["settings"]);
                    this.convertFromJsonObject(tempObj);
                  } else {
                   
                  }

                  resolve(null);
              }).catch(err => {
                  reject(err);
                  console.log(err);
              });
          }
        )

    }

    persist() {

        return new Promise((resolve, reject) => {
            let jsonString: string = JSON.stringify(this.settings);
            this.sql.query("SELECT * FROM persistedUserData WHERE id=" + this.registeredListIndex).then(result => {
                let hasRow: boolean = false;

                if (!(document.URL.indexOf('http://localhost') >= 0 || document.URL.indexOf('https://localhost') >= 0)) {
                    if (result.rows.length > 0) {
                        hasRow = true;
                    }
                } else {
                    if (result.rows.length > 0) {
                        hasRow = true;
                    }
                }
                let query: string = '';
                let params: any;
                if (hasRow) {
                    query = "UPDATE persistedUserData SET settings = ? WHERE id = ?";
                    params =[ jsonString, this.registeredListIndex];
                } else {
                    query = "INSERT INTO persistedUserData VALUES (?,?)";
                    params = [this.registeredListIndex, jsonString];
                }
                this.sql.query(query, params).then(() => {
                    resolve(null);
                });

            }).catch(() => {
                reject();
            });
        });        
    }

}
