import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { RejectModalComponentComponent } from '../pages/home/reject-modal-component/reject-modal-component.component';
import { GlobalHeaderComponent } from './global-header'
//import { FileUploadModule } from 'ng2-file-upload';
import { NgxFileDropModule } from 'ngx-file-drop';

@NgModule({
  declarations: [
    RejectModalComponentComponent,
    GlobalHeaderComponent
  ],
    imports: [TranslateModule,IonicModule, NgxFileDropModule],
	  exports: [TranslateModule, RejectModalComponentComponent, GlobalHeaderComponent],
    schemas:[CUSTOM_ELEMENTS_SCHEMA] 
   
})
export class ComponentsModule {

  constructor() {

  }

}
