import { Injectable } from '@angular/core';
import { AlertController, LoadingController } from '@ionic/angular';

import { TranslateService } from '@ngx-translate/core';
import { GlobalLogoutFactoryProvider } from './global-logout-factory';

/*
  Generated class for the CommonUiProvider provider.

  See https://angular.io/guide/dependency-injection for more info on providers
  and Angular DI.
*/
@Injectable()
export class CommonUiProvider {
    private alertPopup: boolean;
    loader: GlobalLoader;

    constructor(public alert: AlertController,
        public load: LoadingController,
        public translate: TranslateService, public globalLogout: GlobalLogoutFactoryProvider) {
        this.loader = new GlobalLoader(load);
    }
    showAlert(message: string) {
        if (!this.alertPopup) {

            let displayMessage = this.alert.create({
                message: '',
                subHeader: message,
                buttons: ['OK']
            }).then(alert => { alert.present() });
            

        }
    }

    handleError(err, onHandledCallback?, customMessageTranslate?, ignoreAlert?) {
        //the user doesn't have permissions or they are not logged in - log them out
        var messages = '';
        if (err.status === 401) {

            messages = this.translate.instant("security_error_logout");
            if (!this.alertPopup) {
                this.alert.create({
                    message: this.translate.instant("dialog_title_error"),
                    subHeader: messages,
                    buttons: [
                        {
                            text: this.translate.instant("general_ok"),
                            handler: () => {
                                this.alertPopup = null;
                                //this.loader.show();
                                this.globalLogout.logout()
                                    .then(() => { this.loader.hide(); })
                                    .catch(() => { this.loader.hide(); });
                            }
                        }
                    ]
                }).then(alert => { this.alertPopup = true;alert.present() });
            }

        } else if (err.status === 403) {

            messages = this.translate.instant("security_error_permission");
            if (!this.alertPopup) {
                this.alert.create({
                    message: this.translate.instant("dialog_title_error"),
                    subHeader: messages,
                    buttons: [
                        {
                            text: this.translate.instant("general_ok"),
                            handler: () => {
                                this.alertPopup = null;

                            }
                        }
                    ]
                }).then(alert => { this.alertPopup = true; alert.present() });
            }

        } else if (err.status === 400 || err.status === 500 || err.status === 404) {

            var errorObject = err.error;
            if (errorObject) {
                if (errorObject.messages) {
                    for (var i = 0; i < errorObject.messages.length; i++) {
                        messages += errorObject.messages[i] + "<br/>";
                    }

                } else {
                    if (customMessageTranslate) {
                        messages = this.translate.instant(customMessageTranslate)
                    } else {
                        messages = this.translate.instant("system_error")
                    }

                }
            } else {
                if (customMessageTranslate) {
                    messages = this.translate.instant(customMessageTranslate)
                } else {
                    messages = this.translate.instant("system_error")
                }

            }

            if (!this.alertPopup) {
                this.alert.create({
                    message: this.translate.instant("dialog_title_error"),
                    subHeader: messages,
                    buttons: [
                        {
                            text: this.translate.instant("general_ok"),
                            handler: () => {
                                if (onHandledCallback)
                                    onHandledCallback();
                                this.alertPopup = null;
                            }
                        }
                    ]
                }).then(alert => { this.alertPopup = true; alert.present() });
            }

        } else if (err.status === -1 || err.status === 502 || err.status === 0 || err.name === "TimeoutError") {
            //timeout or conenctivity issue
            if (ignoreAlert) {
                if (onHandledCallback)
                    onHandledCallback();
            } else {
                this.alert.create({
                    message: this.translate.instant("dialog_title_error"),
                    subHeader: this.translate.instant("connectivity_error"),
                    buttons: [
                        {
                            text: this.translate.instant("general_ok"),
                            handler: () => {
                                if (onHandledCallback)
                                    onHandledCallback();
                                this.alertPopup = null;
                            }
                        }
                    ]
                }).then(alert => { this.alertPopup = true; alert.present() });
            }
        } else {
            if (onHandledCallback)
                onHandledCallback();
            this.alertPopup = null;
        }
    }

}

class GlobalLoader {
    private loading: any; 
    
    show(): Promise<any> {
        //this.load.create();
        //forkJoin().
        return this.load.create({
            message: "",
            spinner: 'crescent', showBackdrop: false
        }).then(alert => {
            this.loading = alert;
            alert.present()
        });
    }

    hide() {
        this.load.dismiss();
    }
    constructor(public load: LoadingController) {

    }
}
