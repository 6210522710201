import { Injectable } from '@angular/core';
//import { Storage } from '@ionic/storage'; //to maybe replace with Capacitor's Storage API. 
import { Storage } from '@ionic/storage-angular';

/*
  Generated class for the SessionSettingsService service.

  See https://angular.io/guide/dependency-injection for more info on providers
  and Angular DI.
*/
@Injectable({
  providedIn: 'root'
})

export class SessionSettingsService {
  private _storage: Storage | null = null;

  currentToken: string | null = '';
  currentUser: any = null;
  deviceID: string = '';
  simSerialNumber: string = '';
  simCountryCode: string = '';
  appVersion: string = '';
  loggedInOffline: boolean = false;
  offLineEmail: string = '';
  offLinePassword: string = '';
  tilePageOpened: boolean = false;
  dashboardPageOpened: boolean = false;
    isLoggedIn: boolean = false;
    captureLocation: boolean = true;
    siteList: Array<any> = new Array<any>();
    departmentList: Array<any> = new Array<any>();
    permissionList: Array<any> = new Array<any>();
    collectingPartyList: Array<any> = new Array<any>();
    hasWebsiteAccess: boolean = false;
    constructor(private storage: Storage) {
      this.init();
  }

  async init(){
    const storage = await this.storage.create();
    this._storage = storage;
  }

  public async set(key: string, value:any){
    let res = await this._storage?.set(key, value);
  }

  public async get(key: string){
    let val = await this._storage?.get(key);
    return val;
  }

  public async clear(){
    let res = await this._storage?.clear();
  }

  public async remove(key: string){
    let val = await this._storage?.remove(key);
  }

    checkFinished(counter: number): boolean {
      if (counter === 3) {
        return true;
      }
      return false;
    }

    load(): Promise<any> {
      return new Promise<any>((resolve, reject) => {
        var promise1 = this.storage.get('currentToken').then((val) => { this.currentToken = val; });
        var promise2 = this.storage.get('currentUser').then((val) => { this.currentUser = val; });
        var promise3 = this.storage.get('hasWebsiteAccess').then((val) => { this.hasWebsiteAccess = val; });
        var promise4 = this.storage.get('permissionList').then((val) => { this.permissionList = val; });
        var promise5 = this.storage.get('collectingPartyList').then((val) => { this.collectingPartyList = val; });

        Promise.all([promise1,
          promise2,
          promise3,
          promise4,
          promise5,
          
        ]).then(() => {
          resolve(null)
          }).catch(() => {
            reject();
          })
        
      });


    }

    persist(): Promise<any> {
      return new Promise<any>((resolve, reject) => {
        var promise1 = this.storage.set('currentToken', this.currentToken);
        var promise2 = this.storage.set('currentUser', this.currentUser);
        var promise3 = this.storage.set('hasWebsiteAccess', this.hasWebsiteAccess);
        var promise4 = this.storage.set('permissionList', this.permissionList);
        var promise5 = this.storage.set('collectingPartyList', this.collectingPartyList);

        Promise.all([promise1,
          promise2,
          promise3,
          promise4,
          promise5
        ]).then(() => {
          resolve(null)
        }).catch(() => {
          reject();
        })

      });
    
    
   
  }
}
