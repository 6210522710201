import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { UrlFactoryProvider } from '../url-factory';
import { User, LoginResult, SecurityPermission, SecurityRole, SecurityUserRole, UserCollectingPartyRole } from '../../shared/models/models';

@Injectable()
export class SecurityProvider {

  constructor(public http: HttpClient, public urlFactory: UrlFactoryProvider) {

  }

  login(email: string, password: string): Promise<LoginResult> {
    return new Promise((resolve, reject) => {
      this.http.post(this.urlFactory.getApiUrl() + '/security/login', { "emailUserName": email, "password": password, "isFullVersion": false }).subscribe(data => {
        resolve(<LoginResult>data);
      }, err => {
        reject(err);
      });
    });
  }

  loginWithToken(token: string, lastLoginDate?: Date): Promise<LoginResult> {
    return new Promise((resolve, reject) => {
      var path = this.urlFactory.getApiUrl() + '/security/login/' + token;
      this.http.get(path).subscribe(data => {
        resolve(<LoginResult>data);
      }, err => {
        reject(err);
      });
    });
  }

  
  getUserList(): Promise<Array<User>> {
	return new Promise((resolve, reject) => { 
		this.http.get( this.urlFactory.getApiUrl() + '/security/users') .subscribe(data => {
          resolve(<Array<User>>data); 
		}, err => { 
			  reject(err); 
		}); 
	}); 
}

  updateUser(user: User): Promise<User> {
	return new Promise((resolve, reject) => { 
		this.http.post( this.urlFactory.getApiUrl() + '/security/users', user) .subscribe(data => {
          resolve(<User>data); 
		}, err => { 
			  reject(err); 
		}); 
	}); 
}

getUser(userId): Promise<User> {
	return new Promise((resolve, reject) => { 
		this.http.get( this.urlFactory.getApiUrl() + '/security/users/' + userId ) .subscribe(data => {
			 resolve(<User>data); 
		}, err => { 
			  reject(err); 
		}); 
	}); 
}

getUserSites(userId) {
	return new Promise((resolve, reject) => { 
		this.http.get( this.urlFactory.getApiUrl() + '/security/users/' + userId + '/sites') .subscribe(data => {
			 resolve(data); 
		}, err => { 
			  reject(err); 
		}); 
	}); 
}

getPermissionList(): Promise<Array<SecurityPermission>> {
	return new Promise((resolve, reject) => { 
		this.http.get( this.urlFactory.getApiUrl() + '/security/permissions') .subscribe(data => {
          resolve(<Array<SecurityPermission>>data); 
		}, err => { 
			  reject(err); 
		}); 
	}); 
}

  getSecurityRoleList(): Promise<Array<SecurityRole>> {
	return new Promise((resolve, reject) => { 
		this.http.get( this.urlFactory.getApiUrl() + '/security/roles') .subscribe(data => {
          resolve(<Array<SecurityRole>>data); 
		}, err => { 
			  reject(err); 
		}); 
	}); 
}


  updateSecurityRole(role: SecurityRole, isNew: boolean): Promise<SecurityRole> {
    if (isNew) {
      return new Promise((resolve, reject) => {
        this.http.put(this.urlFactory.getApiUrl() + '/security/roles', role).subscribe(data => {
          resolve(<SecurityRole>data);
        }, err => {
          reject(err);
        });
      });
    } else {
      return new Promise((resolve, reject) => {
        this.http.post(this.urlFactory.getApiUrl() + '/security/roles', role).subscribe(data => {
          resolve(<SecurityRole>data);
        }, err => {
          reject(err);
        });
      });
    }
  }

  deleteSecurityRole(role: SecurityRole): Promise<SecurityRole> {
    return new Promise((resolve, reject) => {
      this.http.delete(this.urlFactory.getApiUrl() + '/security/roles/' + role.securityRoleId).subscribe(data => {
        resolve(<SecurityRole>data);
      }, err => {
        reject(err);
      });
    });
  }

getSecurityRole(roleId: number): Promise<SecurityRole> {
	return new Promise((resolve, reject) => { 
		this.http.get( this.urlFactory.getApiUrl() + '/security/roles/' + roleId ) .subscribe(data => {
          resolve(<SecurityRole>data); 
		}, err => { 
			  reject(err); 
		}); 
	}); 
}

getSecurityRoleUsers(roleId: number): Promise<Array<SecurityUserRole>> {
	return new Promise((resolve, reject) => { 
		this.http.get( this.urlFactory.getApiUrl() + '/security/roles/' + roleId + '/users' ) .subscribe(data => {
          resolve(<Array<SecurityUserRole>>data); 
		}, err => { 
			  reject(err); 
		}); 
	}); 
}

updateSecurityUserRole(securityRoleId: number, securityUserRoleList: Array<SecurityUserRole>): Promise<boolean> {
  return new Promise((resolve, reject) => {
    this.http.post(this.urlFactory.getApiUrl() + '/security/roles/users', { securityRoleId: securityRoleId, securityUserRoleList: securityUserRoleList }).subscribe(data => {
      resolve(<boolean>data);
    }, err => {
      reject(err);
    });
  });
}

getLoginAuthorisation(): Promise<LoginResult> {
  return new Promise((resolve, reject) => {
    this.http.get(this.urlFactory.getApiUrl() + '/security/login/userTypeAuthorisation/').subscribe(data => {
      resolve(<LoginResult>data);
    }, err => {
      reject(err);
    });
  });
}

getUserServiceProviderRolesByUserId(spId:number) {
  return new Promise((resolve, reject) => { 
    this.http.get( this.urlFactory.getApiUrl() + '/security/UserCollectingPartyRole/byServiceProviderId/'+ spId ) .subscribe(data => {
          resolve(data); 
    }, err => { 
        reject(err); 
    }); 
  }); 
}
getUserCollectingPartyRolesByUserId(userId:number): Promise<Array<UserCollectingPartyRole>> {
  return new Promise((resolve, reject) => { 
    this.http.get( this.urlFactory.getApiUrl() + '/security/UserCollectingPartyRole/byUserId/'+ userId ) .subscribe(data => {
          resolve(<Array<UserCollectingPartyRole>>data); 
    }, err => { 
        reject(err); 
    }); 
  }); 
}

forgotPassword(userEmal: string){
  return new Promise((resolve, reject) => {
    this.http.post(this.urlFactory.getApiUrl() + '/security/ForgotPassword/', { email: userEmal, secretWord: "" })
    .subscribe({
      next: (data) => resolve(data),
      error: (err) => reject(err)
  });
  });
}

checkForgotPasswordLink(token:string){
  return new Promise((resolve, reject) => { 
    this.http.get( this.urlFactory.getApiUrl() + '/security/forgotpassword/' + token ) 
    .subscribe({
      next: (data) => resolve(data),
      error: (err) => reject(err)
  });
  }); 
}

updatePassword(token: string, password: string) {
  return new Promise((resolve, reject) => {
    this.http.post(this.urlFactory.getApiUrl() + '/security/updatepassword', { "token": token, "password": password })
    .subscribe({
      next: (data) => resolve(data),
      error: (err) => reject(err)
  });
  });
}
}
