import { Injectable } from '@angular/core';

import { Platform } from "@ionic/angular";

declare var window: any;

@Injectable()
export class SqlProvider {

    public db: any;
    public dbname: string = 'localDB.db';

    constructor(public platform: Platform) { }

	/**
	 * Init - init database etc. PS! Have to wait for Platform.ready
	 */
    init(): Promise<any> {
        return new Promise(resolve => {
            if (!(document.URL.indexOf('http://localhost') >= 0 || document.URL.indexOf('https://localhost') >= 0)) {
                this.db = window.sqlitePlugin.openDatabase({ name: this.dbname, location: 'default' });
                // console.log("--> running on device: ", this.db);
                resolve(null);
            } else {
                this.db = window.openDatabase(this.dbname, '1.0', 'Test DB', 200000);
                // console.log("--> running in browser: ", this.db);
              resolve(null);
            };
        });
    }

	/**
	 * query - executes sql
	 */
    query(q: string, params?: any): Promise<any> {
        return new Promise((resolve, reject) => {
            params = params || [];
            this.db.transaction((tx) => {
              tx.executeSql(q, params, (tx, res) => {
                
                    resolve(res);
                }, (tx, err) => {
                  
                    reject(err);
                });
            });
        });
    }

    runMultipleQueries (queryList):Promise<any> {
      return new Promise((resolve, reject) => {
        if (!(document.URL.indexOf('http://localhost') >= 0 || document.URL.indexOf('https://localhost') >= 0)) {
          this.db.sqlBatch(queryList,
            () => {
              resolve(null);
            }
            ,
            () => {
              reject();
            }
          );
        } else {
          this.db.transaction((tx) => {
            for (var i = 0; i < queryList.length; i++) {
              tx.executeSql(queryList[i], [], (tx, res) => {

                resolve(res);
              }, (tx, err) => {

                reject(err);
              });
            }           
          }, () => {
            resolve(null);
            }, (error) => {
              reject();
            });
          
        }



      });        

    }

    initializeTables(): Promise<any> {
      return this.runMultipleQueries(['CREATE TABLE IF NOT EXISTS persistedUserData (id integer primary key, settings string)',
        'CREATE TABLE IF NOT EXISTS cachedBulkData (userId integer, siteId integer, departmentId integer, appSection string, cachedData string, updatedDate datetime)',
        'CREATE TABLE IF NOT EXISTS cachedDailyPhotos (photoId bigint, userId integer, siteId integer, departmentId integer, appSection string, cachedData string, updatedDate datetime)']);
    }
}
