import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { UrlFactoryProvider } from './services/url-factory';
import { PersistedUserDataProvider } from './services/persisted-user-data';
import { SessionSettingsService } from './services/session-settings';
import { GlobalLogoutFactoryProvider } from './services/global-logout-factory';
import { ComponentsModule } from "./components/components.module";
import { SqlProvider } from './services/sql';
import { SecurityProvider } from './services/api/security';
import { CollectionOrdersService } from './services/api/collection-orders.service';
import { Drivers, Storage } from '@ionic/storage';

import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpInterceptorProvider } from './services/http-interceptor';

import { CommonUiProvider } from './services/common-ui';

import { NavParamProvider } from './services/nav-param';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { IonicStorageModule } from '@ionic/storage-angular';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
// import { FileUploadModule } from 'ng2-file-upload';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, "/assets/i18n/", ".json");
}


@NgModule({
  declarations: [AppComponent],
  imports: [BrowserModule, IonicModule.forRoot(), HttpClientModule, IonicStorageModule.forRoot(),
    TranslateModule.forRoot({
    loader: {
      provide: TranslateLoader,
      useFactory: HttpLoaderFactory,
      deps: [HttpClient]
    }
  }), AppRoutingModule, ComponentsModule],
  providers: [
    SecurityProvider,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: HTTP_INTERCEPTORS, useClass: HttpInterceptorProvider, multi: true },
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    UrlFactoryProvider,
    PersistedUserDataProvider,
    SessionSettingsService,
    CommonUiProvider,
    GlobalLogoutFactoryProvider,
    SqlProvider,
    Storage,
    NavParamProvider],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {}
